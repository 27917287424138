// Use on hovers
@mixin transition($property)
  -webkit-transition: $property
  -ms-transition: $property
  transition: $property

// display: inline-flex
@mixin inline-flex
  display: -webkit-inline-box
  display: -ms-inline-flexbox
  display: inline-flex